import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
	{
		key: 'login',
		path: `${AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() => import('views/auth-views/authentication/login')),
	},
	{
		key: 'login-1',
		path: `${AUTH_PREFIX_PATH}/login-1`,
		component: React.lazy(() => import('views/auth-views/authentication/login-1')),
	},
	// {
	// 	key: 'login-2',
	// 	path: `${AUTH_PREFIX_PATH}/login-2`,
	// 	component: React.lazy(() => import('views/auth-views/authentication/login-2')),
	// },
	// {
	// 	key: 'register-1',
	// 	path: `${AUTH_PREFIX_PATH}/register-1`,
	// 	component: React.lazy(() => import('views/auth-views/authentication/register-1')),
	// },
	// {
	// 	key: 'register-2',
	// 	path: `${AUTH_PREFIX_PATH}/register-2`,
	// 	component: React.lazy(() => import('views/auth-views/authentication/register-2')),
	// },
	{
		key: 'forgot-password',
		path: `${AUTH_PREFIX_PATH}/forgot-password`,
		component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
	},
	{
		key: 'change-password',
		path: `${AUTH_PREFIX_PATH}/change-password/:token`,
		component: React.lazy(() => import('views/auth-views/authentication/change-password')),
	},
	{
		key: 'error-page-1',
		path: `${AUTH_PREFIX_PATH}/error-page-1`,
		component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
	},
	{
		key: 'error-page-2',
		path: `${AUTH_PREFIX_PATH}/error-page-2`,
		component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
	},
];

export const protectedRoutes = [
	{
		key: 'dashboard.default',
		path: `${APP_PREFIX_PATH}/dashboards/default`,
		component: React.lazy(() => import('views/app-views/dashboards/default')),
	},
	{
		key: 'branch-performance',
		path: `${APP_PREFIX_PATH}/dashboards/branch-performance`,
		component: React.lazy(() => import('views/app-views/dashboards/branch-performance')),
	},
	{
		key: 'sales-advisor-performance',
		path: `${APP_PREFIX_PATH}/dashboards/sales-advisor-performance`,
		component: React.lazy(() => import('views/app-views/dashboards/sales-advisor-performance')),
	},
	{
		key: 'sales-advisor-activity-logs',
		path: `${APP_PREFIX_PATH}/dashboards/sales-advisor-activity-logs`,
		component: React.lazy(() => import('views/app-views/dashboards/sales-advisor-activity-logs')),
	},
	{
		key: 'service-advisors-activity-logs',
		path: `${APP_PREFIX_PATH}/dashboards/service-advisors-activity-logs`,
		component: React.lazy(() => import('views/app-views/dashboards/service-advisors-activity-logs')),
	},

	{
		key: 'team-members',
		path: `${APP_PREFIX_PATH}/team-members/team-members`,
		component: React.lazy(() => import('views/app-views/team-members/team-members')),
	},
	{
		key: 'branch-manager',
		path: `${APP_PREFIX_PATH}/team-members/branch-manager`,
		component: React.lazy(() => import('views/app-views/team-members/branch-manager')),
	},
	{
		key: 'sales-advisers-team',
		path: `${APP_PREFIX_PATH}/team-members/sales-advisers-team`,
		component: React.lazy(() => import('views/app-views/team-members/sales-advisers-team')),
	},
	{
		key: 'service-advisers-team',
		path: `${APP_PREFIX_PATH}/team-members/service-advisers-team`,
		component: React.lazy(() => import('views/app-views/team-members/service-advisers-team')),
	},

	{
		key: 'leads-management',
		path: `${APP_PREFIX_PATH}/leads-management/leads-management`,
		component: React.lazy(() => import('views/app-views/leads-management/leads-management')),
	},
	{
		key: 'add-lead-source-status',
		path: `${APP_PREFIX_PATH}/leads-management/add-lead-source-status`,
		component: React.lazy(() => import('views/app-views/leads-management/add-lead-source-status')),
	},

	{
		key: 'profile-under-review',
		path: `${APP_PREFIX_PATH}/profile-under-review`,
		component: React.lazy(() => import('views/app-views/profile-under-review')),
	},

	{
		key: 'subscription-plans',
		path: `${APP_PREFIX_PATH}/subscriptions/subscription-plans`,
		component: React.lazy(() => import('views/app-views/subscriptions/subscription-plans')),
	},
	{
		key: 'subscribed-users',
		path: `${APP_PREFIX_PATH}/subscriptions/subscribed-users`,
		component: React.lazy(() => import('views/app-views/subscriptions/subscribed-users')),
	},
	{
		key: 'expired-subscription-users',
		path: `${APP_PREFIX_PATH}/subscriptions/expired-subscription-users`,
		component: React.lazy(() => import('views/app-views/subscriptions/expired-subscription-users')),
	},

	{
		key: 'registered-users',
		path: `${APP_PREFIX_PATH}/app-users/registered-users`,
		component: React.lazy(() => import('views/app-views/app-users/registered-users')),
	},
	{
		key: 'drafted-profile-users',
		path: `${APP_PREFIX_PATH}/app-users/drafted-profile-users`,
		component: React.lazy(() => import('views/app-views/app-users/drafted-profile-users')),
	},
	{
		key: 'brides',
		path: `${APP_PREFIX_PATH}/app-users/brides`,
		component: React.lazy(() => import('views/app-views/app-users/brides')),
	},
	{
		key: 'grooms',
		path: `${APP_PREFIX_PATH}/app-users/grooms`,
		component: React.lazy(() => import('views/app-views/app-users/grooms')),
	},

	{
		key: 'dashboard.analytic',
		path: `${APP_PREFIX_PATH}/dashboards/analytic`,
		component: React.lazy(() => import('views/app-views/dashboards/analytic')),
	},
	{
		key: 'dashboard.sales',
		path: `${APP_PREFIX_PATH}/dashboards/sales`,
		component: React.lazy(() => import('views/app-views/dashboards/sales')),
	},
	{
		key: 'apps',
		path: `${APP_PREFIX_PATH}/apps`,
		component: React.lazy(() => import('views/app-views/apps')),
	},
	{
		key: 'apps.mail',
		path: `${APP_PREFIX_PATH}/apps/mail/*`,
		component: React.lazy(() => import('views/app-views/apps/mail')),
	},
	{
		key: 'apps.chat',
		path: `${APP_PREFIX_PATH}/apps/chat/*`,
		component: React.lazy(() => import('views/app-views/apps/chat')),
	},
	{
		key: 'apps.calendar',
		path: `${APP_PREFIX_PATH}/apps/calendar`,
		component: React.lazy(() => import('views/app-views/apps/calendar')),
	},
	{
		key: 'apps.project',
		path: `${APP_PREFIX_PATH}/apps/project`,
		component: React.lazy(() => import('views/app-views/apps/project')),
	},
	{
		key: 'apps.project.list',
		path: `${APP_PREFIX_PATH}/apps/project/list`,
		component: React.lazy(() => import('views/app-views/apps/project/project-list/ProjectList')),
	},
	{
		key: 'apps.project.scrumboard',
		path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
		component: React.lazy(() => import('views/app-views/apps/project/scrumboard')),
	},
	{
		key: 'apps.ecommerce',
		path: `${APP_PREFIX_PATH}/apps/ecommerce`,
		component: React.lazy(() => import('views/app-views/apps/e-commerce')),
	},
	{
		key: 'apps.ecommerce.add-product',
		path: `${APP_PREFIX_PATH}/apps/ecommerce/add-product`,
		component: React.lazy(() => import('views/app-views/apps/e-commerce/add-product')),
	},
	{
		key: 'apps.ecommerce.edit-product',
		path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/:id`,
		component: React.lazy(() => import('views/app-views/apps/e-commerce/edit-product')),
	},
	{
		key: 'apps.ecommerce.product-list',
		path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
		component: React.lazy(() => import('views/app-views/apps/e-commerce/product-list')),
	},
	{
		key: 'apps.ecommerce.orders',
		path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
		component: React.lazy(() => import('views/app-views/apps/e-commerce/orders')),
	},
	{
		key: 'components.general',
		path: `${APP_PREFIX_PATH}/components/general`,
		component: React.lazy(() => import('views/app-views/components/general')),
	},
	{
		key: 'components.general.button',
		path: `${APP_PREFIX_PATH}/components/general/button`,
		component: React.lazy(() => import('views/app-views/components/general/button')),
	},
	{
		key: 'components.general.icon',
		path: `${APP_PREFIX_PATH}/components/general/icon`,
		component: React.lazy(() => import('views/app-views/components/general/icon')),
	},
	{
		key: 'components.general.typography',
		path: `${APP_PREFIX_PATH}/components/general/typography`,
		component: React.lazy(() => import('views/app-views/components/general/typography')),
	},
	{
		key: 'components.general',
		path: `${APP_PREFIX_PATH}/components/layout`,
		component: React.lazy(() => import('views/app-views/components/layout')),
	},
	{
		key: 'components.general.grid',
		path: `${APP_PREFIX_PATH}/components/layout/grid`,
		component: React.lazy(() => import('views/app-views/components/layout/grid')),
	},
	{
		key: 'components.general.layout',
		path: `${APP_PREFIX_PATH}/components/layout/layout`,
		component: React.lazy(() => import('views/app-views/components/layout/layout')),
	},
	{
		key: 'components.navigation',
		path: `${APP_PREFIX_PATH}/components/navigation`,
		component: React.lazy(() => import('views/app-views/components/navigation')),
	},
	{
		key: 'components.navigation.affix',
		path: `${APP_PREFIX_PATH}/components/navigation/affix`,
		component: React.lazy(() => import('views/app-views/components/navigation/affix')),
	},
	{
		key: 'components.navigation.breadcrumb',
		path: `${APP_PREFIX_PATH}/components/navigation/breadcrumb`,
		component: React.lazy(() => import('views/app-views/components/navigation/breadcrumb')),
	},
	{
		key: 'components.navigation.dropdown',
		path: `${APP_PREFIX_PATH}/components/navigation/dropdown`,
		component: React.lazy(() => import('views/app-views/components/navigation/dropdown')),
	},
	{
		key: 'components.navigation.menu',
		path: `${APP_PREFIX_PATH}/components/navigation/menu`,
		component: React.lazy(() => import('views/app-views/components/navigation/menu')),
	},
	{
		key: 'components.navigation.pagination',
		path: `${APP_PREFIX_PATH}/components/navigation/pagination`,
		component: React.lazy(() => import('views/app-views/components/navigation/pagination')),
	},
	{
		key: 'components.navigation.steps',
		path: `${APP_PREFIX_PATH}/components/navigation/steps`,
		component: React.lazy(() => import('views/app-views/components/navigation/steps')),
	},
	{
		key: 'components.data-entry',
		path: `${APP_PREFIX_PATH}/components/data-entry`,
		component: React.lazy(() => import('views/app-views/components/data-entry')),
	},
	{
		key: 'components.data-entry.auto-complete',
		path: `${APP_PREFIX_PATH}/components/data-entry/auto-complete`,
		component: React.lazy(() => import('views/app-views/components/data-entry/auto-complete')),
	},
	{
		key: 'components.data-entry.cascader',
		path: `${APP_PREFIX_PATH}/components/data-entry/cascader`,
		component: React.lazy(() => import('views/app-views/components/data-entry/cascader')),
	},
	{
		key: 'components.data-entry.checkbox',
		path: `${APP_PREFIX_PATH}/components/data-entry/checkbox`,
		component: React.lazy(() => import('views/app-views/components/data-entry/checkbox')),
	},
	{
		key: 'components.data-entry.date-picker',
		path: `${APP_PREFIX_PATH}/components/data-entry/date-picker`,
		component: React.lazy(() => import('views/app-views/components/data-entry/date-picker')),
	},
	{
		key: 'components.data-entry.form',
		path: `${APP_PREFIX_PATH}/components/data-entry/form`,
		component: React.lazy(() => import('views/app-views/components/data-entry/form')),
	},
	{
		key: 'components.data-entry.input',
		path: `${APP_PREFIX_PATH}/components/data-entry/input`,
		component: React.lazy(() => import('views/app-views/components/data-entry/input')),
	},
	{
		key: 'components.data-entry.input-number',
		path: `${APP_PREFIX_PATH}/components/data-entry/input-number`,
		component: React.lazy(() => import('views/app-views/components/data-entry/input-number')),
	},
	{
		key: 'components.data-entry.mentions',
		path: `${APP_PREFIX_PATH}/components/data-entry/mentions`,
		component: React.lazy(() => import('views/app-views/components/data-entry/mentions')),
	},
	{
		key: 'components.data-entry.radio',
		path: `${APP_PREFIX_PATH}/components/data-entry/radio`,
		component: React.lazy(() => import('views/app-views/components/data-entry/radio')),
	},
	{
		key: 'components.data-entry.rate',
		path: `${APP_PREFIX_PATH}/components/data-entry/rate`,
		component: React.lazy(() => import('views/app-views/components/data-entry/rate')),
	},
	{
		key: 'components.data-entry.select',
		path: `${APP_PREFIX_PATH}/components/data-entry/select`,
		component: React.lazy(() => import('views/app-views/components/data-entry/select')),
	},
	{
		key: 'components.data-entry.slider',
		path: `${APP_PREFIX_PATH}/components/data-entry/slider`,
		component: React.lazy(() => import('views/app-views/components/data-entry/slider')),
	},
	{
		key: 'components.data-entry.switch',
		path: `${APP_PREFIX_PATH}/components/data-entry/switch`,
		component: React.lazy(() => import('views/app-views/components/data-entry/switch')),
	},
	{
		key: 'components.data-entry.time-picker',
		path: `${APP_PREFIX_PATH}/components/data-entry/time-picker`,
		component: React.lazy(() => import('views/app-views/components/data-entry/time-picker')),
	},
	{
		key: 'components.data-entry.transfer',
		path: `${APP_PREFIX_PATH}/components/data-entry/transfer`,
		component: React.lazy(() => import('views/app-views/components/data-entry/transfer')),
	},
	{
		key: 'components.data-entry.tree-select',
		path: `${APP_PREFIX_PATH}/components/data-entry/tree-select`,
		component: React.lazy(() => import('views/app-views/components/data-entry/tree-select')),
	},
	{
		key: 'components.data-entry.upload',
		path: `${APP_PREFIX_PATH}/components/data-entry/upload`,
		component: React.lazy(() => import('views/app-views/components/data-entry/upload')),
	},
	{
		key: 'components.data-display',
		path: `${APP_PREFIX_PATH}/components/data-display`,
		component: React.lazy(() => import('views/app-views/components/data-display')),
	},
	{
		key: 'components.data-display.avatar',
		path: `${APP_PREFIX_PATH}/components/data-display/avatar`,
		component: React.lazy(() => import('views/app-views/components/data-display/avatar')),
	},
	{
		key: 'components.data-display.badge',
		path: `${APP_PREFIX_PATH}/components/data-display/badge`,
		component: React.lazy(() => import('views/app-views/components/data-display/badge')),
	},
	{
		key: 'components.data-display.calendar',
		path: `${APP_PREFIX_PATH}/components/data-display/calendar`,
		component: React.lazy(() => import('views/app-views/components/data-display/calendar')),
	},
	{
		key: 'components.data-display.card',
		path: `${APP_PREFIX_PATH}/components/data-display/card`,
		component: React.lazy(() => import('views/app-views/components/data-display/card')),
	},
	{
		key: 'components.data-display.carousel',
		path: `${APP_PREFIX_PATH}/components/data-display/carousel`,
		component: React.lazy(() => import('views/app-views/components/data-display/carousel')),
	},
	{
		key: 'components.data-display.collapse',
		path: `${APP_PREFIX_PATH}/components/data-display/collapse`,
		component: React.lazy(() => import('views/app-views/components/data-display/collapse')),
	},
	{
		key: 'components.data-display.comment',
		path: `${APP_PREFIX_PATH}/components/data-display/comment`,
		component: React.lazy(() => import('views/app-views/components/data-display/comment')),
	},
	{
		key: 'components.data-display.descriptions',
		path: `${APP_PREFIX_PATH}/components/data-display/descriptions`,
		component: React.lazy(() => import('views/app-views/components/data-display/descriptions')),
	},
	{
		key: 'components.data-display.empty',
		path: `${APP_PREFIX_PATH}/components/data-display/empty`,
		component: React.lazy(() => import('views/app-views/components/data-display/empty')),
	},
	{
		key: 'components.data-display.list',
		path: `${APP_PREFIX_PATH}/components/data-display/list`,
		component: React.lazy(() => import('views/app-views/components/data-display/list')),
	},
	{
		key: 'components.data-display.popover',
		path: `${APP_PREFIX_PATH}/components/data-display/popover`,
		component: React.lazy(() => import('views/app-views/components/data-display/popover')),
	},
	{
		key: 'components.data-display.statistic',
		path: `${APP_PREFIX_PATH}/components/data-display/statistic`,
		component: React.lazy(() => import('views/app-views/components/data-display/statistic')),
	},
	{
		key: 'components.data-display.table',
		path: `${APP_PREFIX_PATH}/components/data-display/table`,
		component: React.lazy(() => import('views/app-views/components/data-display/table')),
	},
	{
		key: 'components.data-display.tabs',
		path: `${APP_PREFIX_PATH}/components/data-display/tabs`,
		component: React.lazy(() => import('views/app-views/components/data-display/tabs')),
	},
	{
		key: 'components.data-display.tag',
		path: `${APP_PREFIX_PATH}/components/data-display/tag`,
		component: React.lazy(() => import('views/app-views/components/data-display/tag')),
	},
	{
		key: 'components.data-display.timeline',
		path: `${APP_PREFIX_PATH}/components/data-display/timeline`,
		component: React.lazy(() => import('views/app-views/components/data-display/timeline')),
	},
	{
		key: 'components.data-display.tooltip',
		path: `${APP_PREFIX_PATH}/components/data-display/tooltip`,
		component: React.lazy(() => import('views/app-views/components/data-display/tooltip')),
	},
	{
		key: 'components.data-display.tree',
		path: `${APP_PREFIX_PATH}/components/data-display/tree`,
		component: React.lazy(() => import('views/app-views/components/data-display/tree')),
	},
	{
		key: 'components.feedback',
		path: `${APP_PREFIX_PATH}/components/feedback`,
		component: React.lazy(() => import('views/app-views/components/feedback')),
	},
	{
		key: 'components.feedback.alert',
		path: `${APP_PREFIX_PATH}/components/feedback/alert`,
		component: React.lazy(() => import('views/app-views/components/feedback/alert')),
	},
	{
		key: 'components.feedback.drawer',
		path: `${APP_PREFIX_PATH}/components/feedback/drawer`,
		component: React.lazy(() => import('views/app-views/components/feedback/drawer')),
	},
	{
		key: 'components.feedback.message',
		path: `${APP_PREFIX_PATH}/components/feedback/message`,
		component: React.lazy(() => import('views/app-views/components/feedback/message')),
	},
	{
		key: 'components.feedback.modal',
		path: `${APP_PREFIX_PATH}/components/feedback/modal`,
		component: React.lazy(() => import('views/app-views/components/feedback/modal')),
	},
	{
		key: 'components.feedback.notification',
		path: `${APP_PREFIX_PATH}/components/feedback/notification`,
		component: React.lazy(() => import('views/app-views/components/feedback/notification')),
	},
	{
		key: 'components.feedback.popconfirm',
		path: `${APP_PREFIX_PATH}/components/feedback/popconfirm`,
		component: React.lazy(() => import('views/app-views/components/feedback/popconfirm')),
	},
	{
		key: 'components.feedback.progress',
		path: `${APP_PREFIX_PATH}/components/feedback/progress`,
		component: React.lazy(() => import('views/app-views/components/feedback/progress')),
	},
	{
		key: 'components.feedback.result',
		path: `${APP_PREFIX_PATH}/components/feedback/result`,
		component: React.lazy(() => import('views/app-views/components/feedback/result')),
	},
	{
		key: 'components.feedback.skeleton',
		path: `${APP_PREFIX_PATH}/components/feedback/skeleton`,
		component: React.lazy(() => import('views/app-views/components/feedback/skeleton')),
	},
	{
		key: 'components.feedback.spin',
		path: `${APP_PREFIX_PATH}/components/feedback/spin`,
		component: React.lazy(() => import('views/app-views/components/feedback/spin')),
	},
	{
		key: 'components.other',
		path: `${APP_PREFIX_PATH}/components/other`,
		component: React.lazy(() => import('views/app-views/components/other')),
	},
	{
		key: 'components.other',
		path: `${APP_PREFIX_PATH}/components/other`,
		component: React.lazy(() => import('views/app-views/components/other')),
	},
	{
		key: 'components.other',
		path: `${APP_PREFIX_PATH}/components/other`,
		component: React.lazy(() => import('views/app-views/components/other')),
	},
	{
		key: 'components.other.anchor',
		path: `${APP_PREFIX_PATH}/components/other/anchor`,
		component: React.lazy(() => import('views/app-views/components/other/anchor')),
	},
	{
		key: 'components.other.backtop',
		path: `${APP_PREFIX_PATH}/components/other/backtop`,
		component: React.lazy(() => import('views/app-views/components/other/backtop')),
	},
	{
		key: 'components.other.config-provider',
		path: `${APP_PREFIX_PATH}/components/other/config-provider`,
		component: React.lazy(() => import('views/app-views/components/other/config-provider')),
	},
	{
		key: 'components.other.divider',
		path: `${APP_PREFIX_PATH}/components/other/divider`,
		component: React.lazy(() => import('views/app-views/components/other/divider')),
	},
	{
		key: 'components.chart',
		path: `${APP_PREFIX_PATH}/components/charts`,
		component: React.lazy(() => import('views/app-views/components/charts')),
	},
	{
		key: 'components.chart.apex-charts',
		path: `${APP_PREFIX_PATH}/components/charts/apex-charts`,
		component: React.lazy(() => import('views/app-views/components/charts/apex')),
	},
	{
		key: 'components.chart.chartjs',
		path: `${APP_PREFIX_PATH}/components/charts/chartjs`,
		component: React.lazy(() => import('views/app-views/components/charts/chartjs')),
	},
	{
		key: 'components.maps',
		path: `${APP_PREFIX_PATH}/components/maps`,
		component: React.lazy(() => import('views/app-views/components/maps')),
	},
	{
		key: 'components.maps.simple-map',
		path: `${APP_PREFIX_PATH}/components/maps/simple-map`,
		component: React.lazy(() => import('views/app-views/components/maps/simple-map')),
	},
	{
		key: 'login-1',
		path: `${APP_PREFIX_PATH}/login-1`,
		component: React.lazy(() => import('views/auth-views/authentication/login-1')),
		meta: {
			blankLayout: true,
		},
	},
	{
		key: 'login-2',
		path: `${APP_PREFIX_PATH}/login-2`,
		component: React.lazy(() => import('views/auth-views/authentication/login-2')),
		meta: {
			blankLayout: true,
		},
	},
	{
		key: 'register-1',
		path: `${APP_PREFIX_PATH}/register-1`,
		component: React.lazy(() => import('views/auth-views/authentication/register-1')),
		meta: {
			blankLayout: true,
		},
	},
	{
		key: 'register-2',
		path: `${APP_PREFIX_PATH}/register-2`,
		component: React.lazy(() => import('views/auth-views/authentication/register-2')),
		meta: {
			blankLayout: true,
		},
	},
	{
		key: 'forgot-password',
		path: `${APP_PREFIX_PATH}/forgot-password`,
		component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
		meta: {
			blankLayout: true,
		},
	},
	{
		key: 'error-page-1',
		path: `${APP_PREFIX_PATH}/error-page-1`,
		component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
		meta: {
			blankLayout: true,
		},
	},
	{
		key: 'error-page-2',
		path: `${APP_PREFIX_PATH}/error-page-2`,
		component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
		meta: {
			blankLayout: true,
		},
	},
	{
		key: 'pages',
		path: `${APP_PREFIX_PATH}/pages`,
		component: React.lazy(() => import('views/app-views/pages')),
	},
	{
		key: 'pages.profile',
		path: `${APP_PREFIX_PATH}/pages/profile`,
		component: React.lazy(() => import('views/app-views/pages/profile')),
	},
	{
		key: 'pages.invoice',
		path: `${APP_PREFIX_PATH}/pages/invoice`,
		component: React.lazy(() => import('views/app-views/pages/invoice')),
	},
	{
		key: 'pages.pricing',
		path: `${APP_PREFIX_PATH}/pages/pricing`,
		component: React.lazy(() => import('views/app-views/pages/pricing')),
	},
	{
		key: 'pages.faq',
		path: `${APP_PREFIX_PATH}/pages/faq`,
		component: React.lazy(() => import('views/app-views/pages/faq')),
	},
	{
		key: 'pages.setting',
		path: `${APP_PREFIX_PATH}/pages/setting/*`,
		component: React.lazy(() => import('views/app-views/pages/setting')),
	},
	{
		key: 'pages.user-list',
		path: `${APP_PREFIX_PATH}/pages/user-list`,
		component: React.lazy(() => import('views/app-views/pages/user-list')),
	},
	{
		key: 'docs.documentation',
		path: `${APP_PREFIX_PATH}/docs/documentation/*`,
		component: React.lazy(() => import('views/app-views/docs')),
	},
];

export const branchManagerRoutes = [
	{
		key: 'bm-dashboard',
		path: `${APP_PREFIX_PATH}/branch-manager/dashboards`,
		component: React.lazy(() => import('views/branch-manager/dashboards/dashboard')),
	},
	{
		key: 'bm-branch-performance',
		path: `${APP_PREFIX_PATH}/branch-manager/branch-performance`,
		component: React.lazy(() => import('views/branch-manager/dashboards/branch-performance')),
	},
	{
		key: 'bm-sales-advisor-performance',
		path: `${APP_PREFIX_PATH}/branch-manager/sales-advisor-performance`,
		component: React.lazy(() => import('views/branch-manager/dashboards/sales-advisor-performance')),
	},
	{
		key: 'sales-advisor-activity-logs',
		path: `${APP_PREFIX_PATH}/branch-manager/sales-advisor-activity-logs`,
		component: React.lazy(() => import('views/branch-manager/dashboards/sales-advisor-activity-logs')),
	},
	{
		key: 'service-advisors-activity-logs',
		path: `${APP_PREFIX_PATH}/branch-manager/service-advisors-activity-logs`,
		component: React.lazy(() => import('views/branch-manager/dashboards/service-advisors-activity-logs')),
	},

	// {
	// 	key: 'bm-team-members',
	// 	path: `${APP_PREFIX_PATH}/branch-manager/team-members`,
	// 	component: React.lazy(() => import('views/branch-manager/team-members/team-members')),
	// },
	// {
	// 	key: 'bm-branch-manager',
	// 	path: `${APP_PREFIX_PATH}/branch-manager/branch-manager`,
	// 	component: React.lazy(() => import('views/branch-manager/team-members/branch-manager')),
	// },
	{
		key: 'bm-sales-advisers-team',
		path: `${APP_PREFIX_PATH}/branch-manager/sales-advisers-team`,
		component: React.lazy(() => import('views/branch-manager/team-members/sales-advisers-team')),
	},
	{
		key: 'bm-service-advisers-team',
		path: `${APP_PREFIX_PATH}/branch-manager/service-advisers-team`,
		component: React.lazy(() => import('views/branch-manager/team-members/service-advisers-team')),
	},

	{
		key: 'bm-leads-management',
		path: `${APP_PREFIX_PATH}/branch-manager/leads-management`,
		component: React.lazy(() => import('views/branch-manager/leads-management/leads-management')),
	},
	// {
	// 	key: 'bm-add-lead-source-status',
	// 	path: `${APP_PREFIX_PATH}/branch-manager/add-lead-source-status`,
	// 	component: React.lazy(() => import('views/branch-manager/leads-management/add-lead-source-status')),
	// },

	{
		key: 'bm-profile-under-review',
		path: `${APP_PREFIX_PATH}/branch-manager/profile-under-review`,
		component: React.lazy(() => import('views/branch-manager/profile-under-review')),
	},

	{
		key: 'bm-subscription-plans',
		path: `${APP_PREFIX_PATH}/branch-manager/subscriptions/subscription-plans`,
		component: React.lazy(() => import('views/branch-manager/subscriptions/subscription-plans')),
	},
	{
		key: 'bm-subscribed-users',
		path: `${APP_PREFIX_PATH}/branch-manager/subscriptions/subscribed-users`,
		component: React.lazy(() => import('views/branch-manager/subscriptions/subscribed-users')),
	},
	{
		key: 'bm-expired-subscription-users',
		path: `${APP_PREFIX_PATH}/branch-manager/subscriptions/expired-subscription-users`,
		component: React.lazy(() => import('views/branch-manager/subscriptions/expired-subscription-users')),
	},

	{
		key: 'bm-registered-users',
		path: `${APP_PREFIX_PATH}/branch-manager/app-users/registered-users`,
		component: React.lazy(() => import('views/branch-manager/app-users/registered-users')),
	},
	{
		key: 'bm-drafted-profile-users',
		path: `${APP_PREFIX_PATH}/branch-manager/app-users/drafted-profile-users`,
		component: React.lazy(() => import('views/branch-manager/app-users/drafted-profile-users')),
	},
	{
		key: 'bm-brides',
		path: `${APP_PREFIX_PATH}/branch-manager/app-users/brides`,
		component: React.lazy(() => import('views/branch-manager/app-users/brides')),
	},
	{
		key: 'bm-grooms',
		path: `${APP_PREFIX_PATH}/branch-manager/app-users/grooms`,
		component: React.lazy(() => import('views/branch-manager/app-users/grooms')),
	},
];

export const salesAdvisorRoutes = [
	{
		key: 'sa-dashboard',
		path: `${APP_PREFIX_PATH}/sales-advisor/dashboards`,
		component: React.lazy(() => import('views/sales-advisor/dashboards/dashboard')),
	},
	// {
	// 	key: 'sa-branch-performance',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/branch-performance`,
	// 	component: React.lazy(() => import('views/sales-advisor/dashboards/branch-performance')),
	// },
	{
		key: 'sa-sales-advisor-performance',
		path: `${APP_PREFIX_PATH}/sales-advisor/sales-advisor-performance`,
		component: React.lazy(() => import('views/sales-advisor/dashboards/sales-advisor-performance')),
	},
	{
		key: 'sales-advisor-activity-logs',
		path: `${APP_PREFIX_PATH}/sales-advisor/sales-advisor-activity-logs`,
		component: React.lazy(() => import('views/sales-advisor/dashboards/sales-advisor-activity-logs')),
	},
	// {
	// 	key: 'service-advisors-activity-logs',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/service-advisors-activity-logs`,
	// 	component: React.lazy(() => import('views/sales-advisor/dashboards/service-advisors-activity-logs')),
	// },

	// {
	// 	key: 'sa-team-members',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/team-members`,
	// 	component: React.lazy(() => import('views/sales-advisor/team-members/team-members')),
	// },
	// {
	// 	key: 'sa-branch-manager',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/branch-manager`,
	// 	component: React.lazy(() => import('views/sales-advisor/team-members/branch-manager')),
	// },
	// {
	// 	key: 'sa-sales-advisers-team',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/sales-advisers-team`,
	// 	component: React.lazy(() => import('views/sales-advisor/team-members/sales-advisers-team')),
	// },
	// {
	// 	key: 'sa-service-advisers-team',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/service-advisers-team`,
	// 	component: React.lazy(() => import('views/sales-advisor/team-members/service-advisers-team')),
	// },

	{
		key: 'sa-leads-management',
		path: `${APP_PREFIX_PATH}/sales-advisor/leads-management`,
		component: React.lazy(() => import('views/sales-advisor/leads-management/leads-management')),
	},
	// {
	// 	key: 'sa-add-lead-source-status',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/add-lead-source-status`,
	// 	component: React.lazy(() => import('views/sales-advisor/leads-management/add-lead-source-status')),
	// },

	// {
	// 	key: 'sa-profile-under-review',
	// 	path: `${APP_PREFIX_PATH}/sales-advisor/profile-under-review`,
	// 	component: React.lazy(() => import('views/sales-advisor/profile-under-review')),
	// },

	{
		key: 'sa-subscription-plans',
		path: `${APP_PREFIX_PATH}/sales-advisor/subscriptions/subscription-plans`,
		component: React.lazy(() => import('views/sales-advisor/subscriptions/subscription-plans')),
	},
	{
		key: 'sa-subscribed-users',
		path: `${APP_PREFIX_PATH}/sales-advisor/subscriptions/subscribed-users`,
		component: React.lazy(() => import('views/sales-advisor/subscriptions/subscribed-users')),
	},
	{
		key: 'sa-expired-subscription-users',
		path: `${APP_PREFIX_PATH}/sales-advisor/subscriptions/expired-subscription-users`,
		component: React.lazy(() => import('views/sales-advisor/subscriptions/expired-subscription-users')),
	},

	{
		key: 'sa-registered-users',
		path: `${APP_PREFIX_PATH}/sales-advisor/app-users/registered-users`,
		component: React.lazy(() => import('views/sales-advisor/app-users/registered-users')),
	},
	{
		key: 'sa-drafted-profile-users',
		path: `${APP_PREFIX_PATH}/sales-advisor/app-users/drafted-profile-users`,
		component: React.lazy(() => import('views/sales-advisor/app-users/drafted-profile-users')),
	},
	{
		key: 'sa-brides',
		path: `${APP_PREFIX_PATH}/sales-advisor/app-users/brides`,
		component: React.lazy(() => import('views/sales-advisor/app-users/brides')),
	},
	{
		key: 'sa-grooms',
		path: `${APP_PREFIX_PATH}/sales-advisor/app-users/grooms`,
		component: React.lazy(() => import('views/sales-advisor/app-users/grooms')),
	},

	{
		key: 'sa-user-details',
		path: `${APP_PREFIX_PATH}/sales-advisor/user-details`,
		component: React.lazy(() => import('views/sales-advisor/user-details')),
	},
	{
		key: 'sa-user-details-preview',
		path: `${APP_PREFIX_PATH}/sales-advisor/user-details-preview`,
		component: React.lazy(() => import('views/sales-advisor/user-details-preview')),
	},
];

export const serviceAdvisorRoutes = [
	{
		key: 'sap-dashboard',
		path: `${APP_PREFIX_PATH}/service-advisor/dashboards`,
		component: React.lazy(() => import('views/service-advisor/dashboards/dashboard')),
	},
	// {
	// 	key: 'sap-branch-performance',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/branch-performance`,
	// 	component: React.lazy(() => import('views/service-advisor/dashboards/branch-performance')),
	// },
	// {
	// 	key: 'sap-sales-advisor-performance',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/sales-advisor-performance`,
	// 	component: React.lazy(() => import('views/service-advisor/dashboards/sales-advisor-performance')),
	// },
	// {
	// 	key: 'saples-advisor-activity-logs',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/sales-advisor-activity-logs`,
	// 	component: React.lazy(() => import('views/service-advisor/dashboards/sales-advisor-activity-logs')),
	// },
	{
		key: 'sap-service-advisors-activity-logs',
		path: `${APP_PREFIX_PATH}/service-advisor/service-advisors-activity-logs`,
		component: React.lazy(() => import('views/service-advisor/dashboards/service-advisors-activity-logs')),
	},
	{
		key: 'sap-candidate-profile',
		path: `${APP_PREFIX_PATH}/service-advisor/candidate-profile`,
		component: React.lazy(() => import('views/service-advisor/profile-sharing/candidate-profile')),
	},
	{
		key: 'sap-shared-profile-details',
		path: `${APP_PREFIX_PATH}/service-advisor/shared-profile-details`,
		component: React.lazy(() => import('views/service-advisor/profile-sharing/shared-profile-details')),
	},
	{
		key: 'sap-shared-viewed-profile',
		path: `${APP_PREFIX_PATH}/service-advisor/shared-viewed-profile`,
		component: React.lazy(() => import('views/service-advisor/profile-sharing/shared-viewed-profile')),
	},
	{
		key: 'sap-viewed-profile-details',
		path: `${APP_PREFIX_PATH}/service-advisor/viewed-profile-details`,
		component: React.lazy(() => import('views/service-advisor/profile-sharing/viewed-profile-details')),
	},

	// {
	// 	key: 'sap-team-members',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/team-members`,
	// 	component: React.lazy(() => import('views/service-advisor/team-members/team-members')),
	// },
	// {
	// 	key: 'sap-branch-manager',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/branch-manager`,
	// 	component: React.lazy(() => import('views/service-advisor/team-members/branch-manager')),
	// },
	// {
	// 	key: 'sap-sales-advisers-team',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/sales-advisers-team`,
	// 	component: React.lazy(() => import('views/service-advisor/team-members/sales-advisers-team')),
	// },
	// {
	// 	key: 'sap-service-advisers-team',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/service-advisers-team`,
	// 	component: React.lazy(() => import('views/service-advisor/team-members/service-advisers-team')),
	// },

	// {
	// 	key: 'sap-leads-management',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/leads-management`,
	// 	component: React.lazy(() => import('views/service-advisor/leads-management/leads-management')),
	// },
	// {
	// 	key: 'sap-add-lead-source-status',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/add-lead-source-status`,
	// 	component: React.lazy(() => import('views/service-advisor/leads-management/add-lead-source-status')),
	// },

	// {
	// 	key: 'sap-profile-under-review',
	// 	path: `${APP_PREFIX_PATH}/service-advisor/profile-under-review`,
	// 	component: React.lazy(() => import('views/service-advisor/profile-under-review')),
	// },

	{
		key: 'sap-subscription-plans',
		path: `${APP_PREFIX_PATH}/service-advisor/subscriptions/subscription-plans`,
		component: React.lazy(() => import('views/service-advisor/subscriptions/subscription-plans')),
	},
	{
		key: 'sap-subscribed-users',
		path: `${APP_PREFIX_PATH}/service-advisor/subscriptions/subscribed-users`,
		component: React.lazy(() => import('views/service-advisor/subscriptions/subscribed-users')),
	},
	{
		key: 'sap-expired-subscription-users',
		path: `${APP_PREFIX_PATH}/service-advisor/subscriptions/expired-subscription-users`,
		component: React.lazy(() => import('views/service-advisor/subscriptions/expired-subscription-users')),
	},

	{
		key: 'sap-registered-users',
		path: `${APP_PREFIX_PATH}/service-advisor/app-users/registered-users`,
		component: React.lazy(() => import('views/service-advisor/app-users/registered-users')),
	},
	{
		key: 'sap-drafted-profile-users',
		path: `${APP_PREFIX_PATH}/service-advisor/app-users/drafted-profile-users`,
		component: React.lazy(() => import('views/service-advisor/app-users/drafted-profile-users')),
	},
	{
		key: 'sap-brides',
		path: `${APP_PREFIX_PATH}/service-advisor/app-users/brides`,
		component: React.lazy(() => import('views/service-advisor/app-users/brides')),
	},
	{
		key: 'sap-grooms',
		path: `${APP_PREFIX_PATH}/service-advisor/app-users/grooms`,
		component: React.lazy(() => import('views/service-advisor/app-users/grooms')),
	},
];
