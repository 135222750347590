export const userType = {
    branchManager: "branch_manager",
    admin: "admin",
    salesAdviser: "sales_advisor",
    serviceAdviser: "service_advisor"
}

export const userTypeLabels = {
    [userType.admin]: "Admin",
    [userType.branchManager]: "Branch Manager",
    [userType.salesAdviser]: "Sales Advisor",
    [userType.serviceAdviser]: "Service Advisor"
}

export const appUserViews = {
    draft: "draft",
    bride: "bride",
    groom: "groom"
}