import { AUTH_PREFIX_PATH, REDIRECT_URL_KEY, UNAUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = () => {
	const token = localStorage.getItem('token');

	const location = useLocation();

	if (!token) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

	return <Outlet />;
};

export default ProtectedRoute;
