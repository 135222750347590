import { appsNavTree, branchManagerNavTree, salesAdvisorNavTree, serviceAdvisorNavTree } from 'configs/NavigationConfig';
import { useEffect, useState } from 'react';
import { createContext } from 'react';
import * as GeneralConstants from '../constants/general'

export const NavigationContext = createContext(() => {
	const userType = localStorage.getItem('user_type');
	switch (userType) {
		case GeneralConstants.userType.admin:
			return { navigationConfig: appsNavTree };

		case GeneralConstants.userType.branchManager:
			return { navigationConfig: branchManagerNavTree };

		case GeneralConstants.userType.salesAdviser:
			return salesAdvisorNavTree;

		case GeneralConstants.userType.serviceAdviser:
			return serviceAdvisorNavTree;

		default:
			return '';
	}
});

export const NavigationProvider = ({ children }) => {
	const [userType, setUserType] = useState(localStorage.getItem('user_type'));

	useEffect(() => {
		setUserType(localStorage.getItem('user_type'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('user_type')]);

	const [navigationConfig] = useState(() => {
		// const userType = localStorage.getItem('user_type');

		switch (userType) {
			case GeneralConstants.userType.admin:
				return appsNavTree;

			case GeneralConstants.userType.branchManager:
				return branchManagerNavTree;

			case GeneralConstants.userType.salesAdviser:
				return salesAdvisorNavTree;

			case GeneralConstants.userType.serviceAdviser:
				return serviceAdvisorNavTree;

			default:
				return '';
		}
	}, []);

	// const [userType] = useState(() => {
	// 	return localStorage.getItem('user_type');
	// });

	return <NavigationContext.Provider value={{ navigationConfig, userType }}>{children}</NavigationContext.Provider>;
};
